import React from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';
import Icon from './Icon';
import UserMenu from './UserMenu';
import fugelogo from '../assets/fugelogo1.png';

function Header() {
  return (
    <header className="bg-blue-900 text-white p-1 flex justify-between items-center shadow-md">
      <div className="flex items-center space-x-4 ml-2">
        <a href="/">
          <img src={fugelogo} alt="Fuge Logo" className="h-16" />
        </a>
        <h1 className="text-xl font-bold tracking-wide">Veteriner Hizmetleri</h1>
      </div>

      <div className="flex-1 flex justify-center">
        <div className="w-2/3">
          <SearchBar />
        </div>
      </div>

      <div className="flex items-center space-x-6 mr-4">
        <Icon />
        <UserMenu />
      </div>
    </header>
  );
}

export default Header;
