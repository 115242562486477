import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function ChangePasswordScreen() {
  const [oldPassword, setOldPassword] = useState('');  // New state for old password
  const [newPassword, setNewPassword] = useState('');
  const navigate = useNavigate();

  const handleChangePassword = async () => {
    try {
      const response = await fetch('https://api.dev1.fugesoft.com/api/change-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          old_password: oldPassword,  // Send old password
          new_password: newPassword   // Send new password
        }),
      });

      if (response.ok) {
        alert('Password changed successfully');
        navigate('/overview'); // Navigate to the next page on successful password change
      } else {
        const data = await response.json();
        alert(data.detail); // Show error message from the backend
      }
    } catch (error) {
      console.error('Password change failed:', error);
      alert('Password change failed. Please try again.');
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-white p-8 rounded shadow-md max-w-sm w-full">
        <h2 className="text-2xl font-bold mb-6 text-center">Change Password</h2>
        <input
          type="password"
          placeholder="Old Password"  // Input field for old password
          className="w-full p-2 mb-4 border rounded"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="New Password"  // Input field for new password
          className="w-full p-2 mb-4 border rounded"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <button
          className="w-full bg-green-600 text-white p-2 rounded hover:bg-green-700"
          onClick={handleChangePassword}
        >
          Change Password
        </button>
      </div>
    </div>
  );
}

export default ChangePasswordScreen;
