import React, { useState, useEffect } from 'react';
import {
  FaTasks,
  FaUserCheck,
  FaMoneyBillWave,
  FaFileAlt,
  FaClipboardList,
  FaComments,
  FaWhatsapp,
  FaEdit,
  FaDoorOpen,
} from 'react-icons/fa';
import Modal from 'react-modal';
import KanbanBoard from '../KanbanBoard/KanbanBoard';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../../Navbar';

Modal.setAppElement('#root');

function PetDetails() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalNotes, setModalNotes] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [pet, setPet] = useState(null);
  const [editableFields, setEditableFields] = useState({});
  const [newNote, setNewNote] = useState('');
  const { petId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch pet details from API using petId
    fetchPetDetails();
  }, [petId]);

  const fetchPetDetails = () => {
    const token = localStorage.getItem("authToken");
    axios
      .get(`https://api.dev1.fugesoft.com/api/pets/${petId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPet(response.data);
        setEditableFields(response.data);
      })
      .catch((error) => console.error("Error fetching pet details:", error));
  };
  

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (field, value) => {
    setEditableFields((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSave = () => {
    const token = localStorage.getItem("authToken");
    axios
      .put(`https://api.dev1.fugesoft.com/api/pets/${petId}`, editableFields, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPet(response.data); // Update pet data with the response
        setEditableFields(response.data); // Sync editable fields
        fetchPetDetails(); // Fetch the updated data immediately after saving
        setIsEditing(false); // Exit editing mode
      })
      .catch((error) => console.error("Error updating pet details:", error));
  };
  

  const handleDischarge = () => {
    alert('Taburcu işlemi gerçekleştirildi.'); // Placeholder action for discharge
  };

  const openModal = (content, notes = []) => {
    setModalContent(content);
    setModalNotes(notes);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const addNote = () => {
    if (newNote.trim()) {
      setModalNotes((prev) => [...prev, newNote]);
      setNewNote('');
    }
  };

  const categories = [
    { title: 'İstek', icon: <FaTasks />, onClick: () => navigate(`/istek-pet/${petId}`) }, // Navigate to IstekPet
    { title: 'Ziyaret', icon: <FaUserCheck /> },
    { title: 'Finans', icon: <FaMoneyBillWave /> },
    { title: 'Döküman', icon: <FaFileAlt /> },
    { title: 'Order', icon: <FaClipboardList /> },
    { title: 'Görüşme', icon: <FaComments /> },
  ];

  if (!pet) return <div>Loading...</div>;

  return (
    <div className="p-4 md:p-6">
      <Navbar title="Yatan Hasta Detayı" onBack={() => navigate('/pet-list')} />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        {/* Pet Information Card */}
        <div className="bg-white p-4 md:p-6 rounded-lg shadow-md relative">
          <div className="absolute top-4 right-4 flex space-x-2">
            {/* Edit Button */}
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded flex items-center"
              onClick={isEditing ? handleSave : handleEditToggle}
            >
              {isEditing ? 'Kaydet' : <FaEdit />}
            </button>
            {/* Taburcu Et Button */}
            <button
              className="bg-red-500 text-white px-3 py-1 rounded flex items-center"
              onClick={handleDischarge}
            >
              <FaDoorOpen className="mr-2" />
              Taburcu Et
            </button>
          </div>
          <div className="grid grid-cols-2 gap-4 text-gray-800">
            {/* Column 1 */}
            <div className="space-y-2">
              <div>
                <p className="font-semibold">Adı:</p>
                {isEditing ? (
                  <input
                    type="text"
                    value={editableFields.name}
                    onChange={(e) => handleInputChange('name', e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                ) : (
                  <p>{pet.name}</p>
                )}
              </div>
              <div>
                <p className="font-semibold">Tür:</p>
                {isEditing ? (
                  <input
                    type="text"
                    value={editableFields.species}
                    onChange={(e) => handleInputChange('species', e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                ) : (
                  <p>{pet.species}</p>
                )}
              </div>
              <div>
                <p className="font-semibold">Irk:</p>
                {isEditing ? (
                  <input
                    type="text"
                    value={editableFields.breed}
                    onChange={(e) => handleInputChange('breed', e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                ) : (
                  <p>{pet.breed}</p>
                )}
              </div>
              <div>
                <p className="font-semibold">Şikayet:</p>
                {isEditing ? (
                  <input
                    type="text"
                    value={editableFields.complaint}
                    onChange={(e) => handleInputChange('complaint', e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                ) : (
                  <p>{pet.complaint}</p>
                )}
              </div>
              <div>
                <p className="font-semibold">Teşhis:</p>
                {isEditing ? (
                  <input
                    type="text"
                    value={editableFields.diagnosis}
                    onChange={(e) => handleInputChange('diagnosis', e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                ) : (
                  <p>{pet.diagnosis}</p>
                )}
              </div>
            </div>

            {/* Column 2 */}
            <div className="space-y-2">
              <div>
                <p className="font-semibold">Sahibi:</p>
                {isEditing ? (
                  <input
                    type="text"
                    value={editableFields.owner_name}
                    onChange={(e) => handleInputChange('owner_name', e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                ) : (
                  <p>{pet.owner_name}</p>
                )}
              </div>
              <div>
                <p className="font-semibold">Tel:</p>
                {isEditing ? (
                  <input
                    type="text"
                    value={editableFields.owner_phone}
                    onChange={(e) => handleInputChange('owner_phone', e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                ) : (
                  <p>{pet.owner_phone}</p>
                )}
              </div>
              <div>
                <p className="font-semibold">Adres:</p>
                {isEditing ? (
                  <textarea
                    value={editableFields.owner_address}
                    onChange={(e) => handleInputChange('owner_address', e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                ) : (
                  <p>{pet.owner_address}</p>
                )}
              </div>
              <div>
                <p className="font-semibold">Mikroçip ID:</p>
                <p>{pet.microchip_id}</p>
              </div>
              <div>
  <p className="font-semibold">Hasta Mizacı:</p>
  {isEditing ? (
    <textarea
      value={editableFields.hasta_mizaci || ''}
      onChange={(e) => handleInputChange('hasta_mizaci', e.target.value)}
      className="w-full p-2 border rounded"
    />
  ) : (
    <p>{pet.hasta_mizaci || 'Belirtilmedi'}</p>
  )}
</div>
            </div>
          </div>
        </div>

        {/* Category Buttons */}
        <div className="grid grid-cols-3 gap-4">
          {categories.map((category, index) => (
            <div
              key={index}
              className="bg-gray-200 p-4 rounded-lg flex items-center justify-center cursor-pointer hover:bg-gray-300 transition"
              onClick={category.onClick || (() => openModal(category.title))}
            >
              <span className="text-center font-semibold flex flex-col items-center">
                <div className="text-3xl mb-2">{category.icon}</div>
                {category.title}
              </span>
            </div>
          ))}
        </div>
      </div>

      {/* Kanban Board */}
      <div className="mt-6">
        <KanbanBoard showNavbar={false} petId={petId} />
      </div>
    </div>
  );
}

export default PetDetails;
