import React from 'react';
import { FaBell } from 'react-icons/fa';

function Icon() {
  return (
    <div className="relative">
      <FaBell className="text-2xl" />
      <span className="absolute top-0 right-0 bg-red-600 text-white text-xs rounded-full px-1">3</span>
    </div>
  );
}

export default Icon;
