import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaWhatsapp, FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import Modal from 'react-modal';
import axios from 'axios';
import Navbar from '../../Navbar';

function PetList() {
  const [searchQuery, setSearchQuery] = useState('');
  const [pets, setPets] = useState([]); // State to hold pets data
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newPet, setNewPet] = useState({
    name: '',
    species: '',
    ownerName: '',
    complaint: '',
    diagnosis: '',
    gender: '',
  });

  const navigate = useNavigate();

  // Fetch pets from the API
  useEffect(() => {
    axios
      .get('https://api.dev1.fugesoft.com/api/pets')
      .then((response) => {
        setPets(response.data); // Assuming the API returns a list of pets
      })
      .catch((error) => {
        console.error('Error fetching pets:', error);
      });
  }, []);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
    setNewPet({
      name: '',
      species: '',
      ownerName: '',
      complaint: '',
      diagnosis: '',
      gender: '',
    });
  };

  // Add new pet to the API
  const handleAddPet = () => {
    axios
      .post('https://api.dev1.fugesoft.com/api/pets', newPet)
      .then((response) => {
        setPets((prevPets) => [...prevPets, response.data]); // Add the new pet to the list
        closeModal();
      })
      .catch((error) => {
        console.error('Error adding pet:', error);
      });
  };

  // Filter pets based on search query
  const filteredPets = pets.filter((pet) =>
    pet.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    pet.ownerName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="space-y-4 p-4">
      <Navbar />

      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Evcil Hayvan Listesi</h2>
        <div className="flex space-x-4">
          <input
            type="text"
            placeholder="Ara..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="p-2 border rounded-md"
          />
          <button
            onClick={openModal}
            className="flex items-center space-x-2 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
          >
            <FaPlus />
            <span>Yeni Hayvan Ekle</span>
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left">Fotoğraf</th>
              <th className="px-6 py-3 text-left">Hasta Adı &amp; Sahip Bilgileri</th>
              <th className="px-6 py-3 text-left">Şikayet / Tanı &amp; Cins</th>
              <th className="px-6 py-3 text-left">İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {filteredPets.map((pet) => (
              <tr
                key={pet._id}
                className="border-b hover:bg-gray-100 cursor-pointer"
                onClick={() => navigate(`/pet-details/${pet._id}`)}
              >
                {/* Pet Photo */}
                <td className="px-6 py-3">
                  <img
                    src={pet.photo || 'https://via.placeholder.com/80'}
                    alt={pet.name}
                    className="h-16 w-16 rounded-full object-cover"
                  />
                </td>

                {/* Owner Info */}
                <td className="px-6 py-3">
                  <div className="flex flex-col">
                    <span className="font-bold text-gray-800">{pet.name}</span>
                    <span className="text-gray-500">{pet.ownerName}</span>
                    <span className="text-gray-500">{pet.ownerPhone}</span>
                    <a
                      href={`https://wa.me/${pet.ownerPhone}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-green-500 mt-1 inline-flex items-center"
                      onClick={(e) => e.stopPropagation()} // Prevent row click
                    >
                      <FaWhatsapp className="mr-1" /> WhatsApp
                    </a>
                  </div>
                </td>

                {/* Complaint, Diagnosis, and Species */}
                <td className="px-6 py-3">
                  <div className="flex flex-col space-y-2">
                    <div>
                      <span className="font-semibold text-gray-800">Şikayet:</span>
                      <p className="text-gray-500">{pet.complaint}</p>
                    </div>
                    <div>
                      <span className="font-semibold text-gray-800">Tanı:</span>
                      <p className="text-gray-500">{pet.diagnosis}</p>
                    </div>
                    <div>
                      <span className="font-semibold text-gray-800">Cins:</span>
                      <p className="text-gray-500">{pet.species}</p>
                    </div>
                  </div>
                </td>

                {/* Actions */}
                <td className="px-6 py-3 text-center">
                  <div className="flex space-x-2">
                    <Link
                      to={`/pet-details/${pet._id}`}
                      className="text-blue-500 hover:underline"
                      onClick={(e) => e.stopPropagation()} // Prevent row click
                    >
                      <FaEdit /> Düzenle
                    </Link>
                    <button
                      className="text-red-500 hover:underline"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent row click
                        // Add delete functionality here
                      }}
                    >
                      <FaTrash /> Sil
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Add Pet Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-lg"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-xl font-bold mb-4">Yeni Hayvan Ekle</h2>
        <form className="space-y-4">
          <input
            type="text"
            placeholder="Adı"
            value={newPet.name}
            onChange={(e) => setNewPet({ ...newPet, name: e.target.value })}
            className="w-full p-2 border rounded"
          />
          <input
            type="text"
            placeholder="Tür"
            value={newPet.species}
            onChange={(e) => setNewPet({ ...newPet, species: e.target.value })}
            className="w-full p-2 border rounded"
          />
          <input
            type="text"
            placeholder="Sahip Adı"
            value={newPet.ownerName}
            onChange={(e) => setNewPet({ ...newPet, ownerName: e.target.value })}
            className="w-full p-2 border rounded"
          />
          <textarea
            placeholder="Şikayet"
            value={newPet.complaint}
            onChange={(e) => setNewPet({ ...newPet, complaint: e.target.value })}
            className="w-full p-2 border rounded"
          />
          <textarea
            placeholder="Tanı"
            value={newPet.diagnosis}
            onChange={(e) => setNewPet({ ...newPet, diagnosis: e.target.value })}
            className="w-full p-2 border rounded"
          />
          <select
            value={newPet.gender}
            onChange={(e) => setNewPet({ ...newPet, gender: e.target.value })}
            className="w-full p-2 border rounded"
          >
            <option value="">Cinsiyet</option>
            <option value="Male">Erkek</option>
            <option value="Female">Dişi</option>
          </select>
          <button
            type="button"
            onClick={handleAddPet}
            className="bg-green-500 text-white py-2 px-4 rounded"
          >
            Kaydet
          </button>
          <button
            type="button"
            onClick={closeModal}
            className="bg-gray-500 text-white py-2 px-4 rounded ml-2"
          >
            İptal
          </button>
        </form>
      </Modal>
    </div>
  );
}

export default PetList;
