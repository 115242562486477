import React, { useState } from "react";
import {
  FaTrashAlt,
  FaFlask,
  FaXRay,
  FaMicrochip,
  FaMicroscope,
  FaBrain,
} from "react-icons/fa";
import Navbar from "../../../Navbar";
import IstekLab from "./IstekLab";
import IstekUSG from "./IstekUSG";
import IstekRontgen from "./IstekRontgen";
import IstekTomografi from "./IstekTomografi";
import IstekMR from "./IstekMR";
import axios from "axios";
import { useParams } from "react-router-dom";

function IstekPet() {
  const [selectedCategory, setSelectedCategory] = useState(""); // Selected category
  const [cart, setCart] = useState([]); // Request cart
  const [currentRequest, setCurrentRequest] = useState({
    category: "",
    description: "",
    bodyPart: "",
  });
  const [loading, setLoading] = useState(false); // Loading state for confirmation button
  const [showModal, setShowModal] = useState(false); // Modal state

  const istekCategories = [
    { title: "Lab", icon: <FaFlask /> },
    { title: "USG", icon: <FaMicrochip /> },
    { title: "Röntgen", icon: <FaXRay /> },
    { title: "Tomografi", icon: <FaMicroscope /> },
    { title: "MR", icon: <FaBrain /> },
  ];
  const { petId } = useParams();

  const token = localStorage.getItem("authToken"); // Token from localStorage

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setCurrentRequest({ category: "", description: "", bodyPart: "" });
  };

  const handleAddToCart = (item) => {
    const newItem = {
      category: selectedCategory || "",
      subcategory: selectedCategory === "Lab" ? currentRequest.category || "" : "",
      test_name: item.test_name || "",
      description: item.description || "-",
    };
    setCart([...cart, newItem]);
  };

  const handleRemoveFromCart = (index) => {
    setCart(cart.filter((_, i) => i !== index));
  };

  const handleConfirmRequests = async () => {
    setShowModal(false); // Modal kapatılıyor
    if (cart.length === 0) return;

    setLoading(true);
    try {
      const requestData = cart.map((item) => ({
        category: item.category || "",
        subcategory: item.subcategory || "",
        test_name: item.test_name || "",
        description: item.description || "-",
        petId: petId || "",
        created_at: "2024-12-07T22:05:02.722Z",
        updated_at: "2024-12-07T22:05:02.722Z",
      }));

      console.log("Gönderilen Veriler:", requestData);

      const response = await axios.post(
        "https://api.dev1.fugesoft.com/api/isteks/array",
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("İstekler başarıyla gönderildi:", response.data);

      setCart([]); // Sepet temizleniyor
    } catch (error) {
      if (error.response) {
        console.error("Backend Hatası:", error.response.data);
      } else {
        console.error("İstek Hatası:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const renderCategoryComponent = () => {
    switch (selectedCategory) {
      case "Lab":
        return (
          <IstekLab
            currentRequest={currentRequest}
            setCurrentRequest={setCurrentRequest}
            handleAddToCart={handleAddToCart}
          />
        );
      case "USG":
        return (
          <IstekUSG
            currentRequest={currentRequest}
            setCurrentRequest={setCurrentRequest}
            handleAddToCart={handleAddToCart}
          />
        );
      case "Röntgen":
        return (
          <IstekRontgen
            currentRequest={currentRequest}
            setCurrentRequest={setCurrentRequest}
            handleAddToCart={handleAddToCart}
          />
        );
      case "Tomografi":
        return (
          <IstekTomografi
            currentRequest={currentRequest}
            setCurrentRequest={setCurrentRequest}
            handleAddToCart={handleAddToCart}
          />
        );
      case "MR":
        return (
          <IstekMR
            currentRequest={currentRequest}
            setCurrentRequest={setCurrentRequest}
            handleAddToCart={handleAddToCart}
          />
        );
      default:
        return <p>Lütfen bir kategori seçiniz.</p>;
    }
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <Navbar title="İstek Ekranı" onBack={() => window.history.back()} />
      <div className="grid grid-cols-12 gap-6 mt-4">
        <div className="col-span-3 bg-white shadow-lg p-4 rounded-lg">
          <h2 className="text-lg font-bold mb-4 text-gray-700">İstek Kategorileri</h2>
          {istekCategories.map((category, index) => (
            <div
              key={index}
              className={`flex items-center p-3 mb-3 cursor-pointer rounded-lg ${
                selectedCategory === category.title
                  ? "bg-blue-500 text-white"
                  : "bg-gray-100 text-gray-700 hover:bg-gray-200"
              }`}
              onClick={() => handleCategoryClick(category.title)}
            >
              <span className="text-xl mr-3">{category.icon}</span>
              {category.title}
            </div>
          ))}
        </div>

        <div className="col-span-9 bg-white shadow-lg p-6 rounded-lg">
          {renderCategoryComponent()}
        </div>
      </div>

      <div className="mt-6 bg-white shadow-lg p-4 rounded-lg">
        <h2 className="text-lg font-bold mb-4 text-gray-700">İstek Sepeti</h2>
        <table className="w-full text-left">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-4 py-2">İstek</th>
              <th className="px-4 py-2">Test</th>
              <th className="px-4 py-2">Açıklama</th>
              <th className="px-4 py-2">Aksiyon</th>
            </tr>
          </thead>
          <tbody>
            {cart.map((item, index) => (
              <tr key={index} className="border-b">
                <td className="px-4 py-2">{item.category}</td>
                <td className="px-4 py-2">
                  {item.subcategory ? `${item.subcategory} - ` : ""}
                  {item.test_name}
                </td>
                <td className="px-4 py-2">{item.description}</td>
                <td className="px-4 py-2">
                  <button
                    onClick={() => handleRemoveFromCart(index)}
                    className="text-red-500"
                  >
                    <FaTrashAlt />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {cart.length > 0 && (
          <button
            onClick={() => setShowModal(true)}
            className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
            disabled={loading}
          >
            {loading ? "İstekler oluşturuluyor..." : "Onayla"}
          </button>
        )}
      </div>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h3 className="text-lg font-bold mb-4">İstekleri onaylamak istiyor musunuz?</h3>
            <div className="flex justify-center gap-4">
              <button
                onClick={handleConfirmRequests}
                className="bg-green-500 text-white px-4 py-2 rounded"
              >
                Onayla
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                İptal
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default IstekPet;
