import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa';
import Modal from 'react-modal';
import axios from 'axios';

function PetOwnerDetailsScreen() {
  const { owner_id } = useParams();  // Get the owner_id from URL
  const [ownerDetails, setOwnerDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const staticOwnerDetails = {
    id: 1,
    tc: '12345678901',
    totalDebt: '2.000 TL',
    totalPaid: '13.597 TL',
    appointments: [
      { id: 1, date: '2024-09-10', details: 'Genel Muayene' },
      { id: 2, date: '2024-09-20', details: 'Aşı Uygulaması' },
      { id: 3, date: '2024-09-25', details: 'Diş Bakımı' },
      { id: 4, date: '2024-09-30', details: 'Göz Kontrolü' },
      { id: 5, date: '2024-10-05', details: 'Kalp Muayenesi' },
      { id: 6, date: '2024-10-10', details: 'Rutin Kontrol' },
    ],
    pets: [
      { id: 101, name: 'Bella', breed: 'Golden Retriever', age: 5 },
      { id: 102, name: 'Max', breed: 'German Shepherd', age: 3 },
    ],
  };

  const [appointments, setAppointments] = useState(staticOwnerDetails.appointments);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newAppointment, setNewAppointment] = useState({ date: '', details: '' });
  const itemsPerPage = 5;

  const totalPages = Math.ceil(appointments.length / itemsPerPage);
  const token = localStorage.getItem('authToken');

  useEffect(() => {
    axios.get(`https://api.dev1.fugesoft.com/api/owners/${owner_id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setOwnerDetails(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching the owner details!', error);
        setIsLoading(false);
      });
  }, [owner_id, token]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewAppointment({ date: '', details: '' });
  };

  const handleAddAppointment = () => {
    setAppointments([...appointments, { id: appointments.length + 1, ...newAppointment }]);
    closeModal();
  };

  const handleDeleteAppointment = (id) => {
    const confirmed = window.confirm('Bu randevuyu silmek istediğinizden emin misiniz?');
    if (confirmed) {
      setAppointments(appointments.filter(appointment => appointment.id !== id));
    }
  };

  const handleEditAppointment = (id) => {
    alert(`Randevu ${id} düzenleniyor...`);
  };

  const paginatedAppointments = appointments.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!ownerDetails) {
    return <div>No owner details found</div>;
  }

  return (
    <div className="p-6">
      <h2 className="text-3xl font-bold mb-6">Hasta Detayları</h2>

      <div className="flex space-x-6">
        <div className="w-1/3 bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-bold mb-4">Hasta Bilgileri</h3>
          <p className="text-gray-700"><strong>İsim:</strong> {ownerDetails.name}</p>
          <p className="text-gray-700"><strong>Telefon:</strong> {ownerDetails.phone_number}</p>
          <p className="text-gray-700"><strong>Adres:</strong> {ownerDetails.address}</p>
          <p className="text-gray-700"><strong>TC:</strong> {ownerDetails.tc}</p>
          <p className="text-gray-700"><strong>Toplam Ödeme:</strong> {ownerDetails.totalPaid}</p>
          <div className="bg-red-100 p-4 rounded-lg shadow-lg mt-6">
            <h3 className="text-xl font-bold text-red-700 mb-4">Toplam Borç</h3>
            <p className="text-red-600 text-2xl font-semibold">{ownerDetails.totalDebt}</p>
          </div>
        </div>

        <div className="w-2/3">
          <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold">Evcil Hayvanlar</h3>
              <button
                onClick={openModal}
                className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 flex items-center"
              >
                <FaPlus className="mr-2" /> Yeni Evcil Hayvan Ekle
              </button>
            </div>
            <div className="grid grid-cols-2 gap-4">
              {staticOwnerDetails.pets.map((pet) => (
                <div key={pet.id} className="bg-gray-100 p-4 rounded-lg shadow-md">
                  <h4 className="text-lg font-bold mb-2">{pet.name}</h4>
                  <p className="text-gray-600"><strong>Cins:</strong> {pet.breed}</p>
                  <p className="text-gray-600"><strong>Yaş:</strong> {pet.age}</p>
                  <Link to={`/pet-details/${pet.id}`} className="text-blue-500 hover:underline">
                    Detaylar
                  </Link>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold">Randevular</h3>
              <button
                onClick={openModal}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 flex items-center"
              >
                <FaPlus className="mr-2" /> Yeni Randevu Ekle
              </button>
            </div>
            <table className="min-w-full bg-white shadow-md rounded-lg">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-left">Tarih</th>
                  <th className="px-6 py-3 text-left">Detaylar</th>
                  <th className="px-6 py-3 text-left">İşlemler</th>
                </tr>
              </thead>
              <tbody>
                {paginatedAppointments.map((appointment) => (
                  <tr key={appointment.id} className="border-b hover:bg-gray-100">
                    <td className="px-6 py-3">{appointment.date}</td>
                    <td className="px-6 py-3">{appointment.details}</td>
                    <td className="px-6 py-3 flex space-x-2">
                      <button
                        onClick={() => handleEditAppointment(appointment.id)}
                        className="text-yellow-500 hover:underline"
                      >
                        <FaEdit />
                      </button>
                      <button
                        onClick={() => handleDeleteAppointment(appointment.id)}
                        className="text-red-500 hover:underline"
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-between mt-4">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-4 py-2 bg-gray-200 rounded-lg disabled:bg-gray-300"
              >
                Önceki
              </button>
              <span>Sayfa {currentPage} / {totalPages}</span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-4 py-2 bg-gray-200 rounded-lg disabled:bg-gray-300"
              >
                Sonraki
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Yeni Randevu Ekle">
        <h2 className="text-xl mb-4">Yeni Randevu Ekle</h2>
        <form onSubmit={(e) => {
          e.preventDefault();
          handleAddAppointment();
        }}>
          <div className="mb-4">
            <label className="block text-gray-700">Tarih</label>
            <input
              type="date"
              value={newAppointment.date}
              onChange={(e) => setNewAppointment({ ...newAppointment, date: e.target.value })}
              className="w-full p-2 border rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Detaylar</label>
            <input
              type="text"
              value={newAppointment.details}
              onChange={(e) => setNewAppointment({ ...newAppointment, details: e.target.value })}
              className="w-full p-2 border rounded-md"
              required
            />
          </div>
          <div className="flex justify-end space-x-4">
            <button type="button" onClick={closeModal} className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600">
              İptal
            </button>
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              Ekle
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default PetOwnerDetailsScreen;
