import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FaTasks,
  FaCommentDots,
  FaAddressBook,
  FaBed,
  FaFileAlt,
  FaShoppingCart,
  FaMedkit,
} from 'react-icons/fa';
import { MdOutlinePets } from 'react-icons/md';
import PetListModal from './YatanHastaListesi/PetListModal';

function ActionButtonKanban() {
  const navigate = useNavigate();
  const [isPetListModalOpen, setIsPetListModalOpen] = useState(false);

  const openPetListModal = () => setIsPetListModalOpen(true);
  const closePetListModal = () => setIsPetListModalOpen(false);

  const buttons = [
    { icon: <FaTasks />, label: 'Yapılacaklar', path: '/kanban-board' },
    { icon: <FaBed />, label: 'Yatan Hasta Listesi', path: '/pet-list' },
    { icon: <FaMedkit />, label: 'Ayakta Tedavi', path: '/outpatient' },
    { icon: <FaFileAlt />, label: 'Ameliyathane', path: '/operation-room' },
    { icon: <FaAddressBook />, label: 'Müşteriler', path: '/patients' },
    { icon: <MdOutlinePets />, label: 'Hayvan Listesi', path: '/petlist' },
    { icon: <FaCommentDots />, label: 'Taburcu ', path: '/discharged' },
    { icon: <FaShoppingCart />, label: 'Finans', path: '/payments' },
  ];

  return (
    <div className="p-6">
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6">
        {buttons.map((button, index) => (
          <button
            key={index}
            onClick={() => navigate(button.path)}
            className="flex flex-col items-center justify-center p-6 rounded-lg shadow-md bg-white hover:bg-gray-100 hover:shadow-lg transition-all duration-300"
          >
            <div className="text-4xl mb-3 text-gray-700">{button.icon}</div>
            <span className="text-lg font-medium text-gray-800">{button.label}</span>
          </button>
        ))}
      </div>

      {/* PetList Modal */}
      <PetListModal isOpen={isPetListModalOpen} onRequestClose={closePetListModal} />
    </div>
  );
}

export default ActionButtonKanban;
