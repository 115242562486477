import React from 'react';
import ActionButtonKanban from './ActionButtonKanban';

function OverviewKanban() {
  return (
    <div className="p-16 min-h-screen flex flex-col items-center bg-gray-50">
      {/* Dashboard Header */}
      <div className="bg-white p-6 shadow-lg w-full max-w-5xl flex items-center justify-between rounded-lg mb-8">
        {/* Title centered */}
        <div className="flex-1 text-center">
          <h1 className="text-3xl font-bold text-blue-700 tracking-wide">
            Vet Clinic Dashboard
          </h1>
          <p className="text-sm text-gray-500 mt-2">
            Hayvan klinik işlemlerinizi kolayca yönetin
          </p>
        </div>
        {/* Emergency Button */}
        <div>
          <button
            onClick={() => alert('Emergency action triggered!')}
            className="bg-red-500 text-white font-semibold py-3 px-6 rounded-lg shadow-md hover:bg-red-600 transition-all duration-300"
          >
            Acil Durum
          </button>
        </div>
      </div>
      
      {/* Action Buttons */}
      <div className="flex justify-center w-full">
        <div className="max-w-5xl w-full">
          <ActionButtonKanban />
        </div>
      </div>
    </div>
  );
}

export default OverviewKanban;
