import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Modal from 'react-modal';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarScreen.css'; // Takvim için özelleştirilmiş stiller

const localizer = momentLocalizer(moment);

// Modal Stilleri
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    padding: '20px',
    zIndex: '1000',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)', // Karanlık arkaplan
    zIndex: '999',
  },
};

function CalendarScreen() {
  const [events, setEvents] = useState([
    {
      owner: 'John Doe',
      pet: 'Bella',
      desc: 'Genel Muayene',
      start: new Date(2024, 8, 10, 10, 0), // 10 Eylül 2024, 10 AM
      end: new Date(2024, 8, 10, 11, 0),
    },
    {
      owner: 'Jane Smith',
      pet: 'Max',
      desc: 'Ameliyat',
      start: new Date(2024, 8, 15, 14, 0), // 15 Eylül 2024, 2 PM
      end: new Date(2024, 8, 15, 16, 0),
    },
    {
      owner: 'Michael Jordan',
      pet: 'Buddy',
      desc: 'Aşılama',
      start: new Date(2024, 8, 24, 9, 0), // 24 Eylül 2024, 9 AM
      end: new Date(2024, 8, 24, 10, 0),
    }
  ]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [newEvent, setNewEvent] = useState({
    owner: '',
    pet: '',
    desc: '',
    start: '',
    end: '',
  });
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);

  // Yeni randevu için modal açma
  const openModal = (slotInfo) => {
    setSelectedSlot(slotInfo);
    const startTime = moment(slotInfo.start).format("YYYY-MM-DDTHH:mm");
    const endTime = moment(slotInfo.end).format("YYYY-MM-DDTHH:mm");

    setNewEvent({
      owner: '',
      pet: '',
      desc: '',
      start: startTime,
      end: endTime,
    });

    setModalIsOpen(true);
  };

  // Mevcut randevuyu düzenlemek için modal açma
  const openEditModal = (event) => {
    setSelectedEvent(event);
    setNewEvent({
      owner: event.owner,
      pet: event.pet,
      desc: event.desc,
      start: moment(event.start).format("YYYY-MM-DDTHH:mm"),
      end: moment(event.end).format("YYYY-MM-DDTHH:mm"),
    });
    setEditModalIsOpen(true);
  };

  // Modalı kapatma işlemleri
  const closeModal = () => {
    setModalIsOpen(false);
    setNewEvent({ owner: '', pet: '', desc: '', start: '', end: '' });
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedEvent(null);
  };

  // Form girişlerini işleme
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEvent((prevEvent) => ({
      ...prevEvent,
      [name]: value,
    }));
  };

  // Yeni randevu ekleme
  const handleAddEvent = () => {
    const { owner, pet, desc, start, end } = newEvent;

    // Başlangıç ve bitiş zamanını Date objelerine dönüştürme
    const startDate = new Date(start);
    const endDate = new Date(end);

    const newAppointment = {
      owner,
      pet,
      desc,
      start: startDate,
      end: endDate,
    };

    // Yeni randevuyu mevcut randevulara ekleme
    setEvents((prevEvents) => [...prevEvents, newAppointment]);

    closeModal(); // Modal kapandıktan sonra randevuyu ekleme
  };

  // Randevuyu düzenleme işlemi
  const handleEditEvent = () => {
    const updatedEvents = events.map((event) =>
      event === selectedEvent
        ? {
            ...event,
            owner: newEvent.owner,
            pet: newEvent.pet,
            desc: newEvent.desc,
            start: new Date(newEvent.start),
            end: new Date(newEvent.end),
          }
        : event
    );
    setEvents(updatedEvents);
    closeEditModal(); // Düzenlemeden sonra modalı kapatma
  };

  // Randevuyu silme işlemi
  const handleDeleteEvent = () => {
    const updatedEvents = events.filter((event) => event !== selectedEvent);
    setEvents(updatedEvents);
    closeEditModal(); // Silmeden sonra modalı kapatma
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Veteriner Kliniği Takvimi</h2>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        titleAccessor={(event) => `${event.owner} - ${event.pet} - ${event.desc}`}
        selectable
        onSelectSlot={(slotInfo) => openModal(slotInfo)}
        onSelectEvent={(event) => openEditModal(event)}
        style={{ height: 600 }}
      />

      {/* Yeni randevu ekleme modalı */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Yeni Randevu Ekle"
      >
        <h2 className="text-xl font-bold mb-4">Yeni Randevu Ekle</h2>

        <div className="mb-4">
          <label className="block mb-2">Sahip</label>
          <input
            type="text"
            name="owner"
            value={newEvent.owner}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Evcil Hayvan</label>
          <input
            type="text"
            name="pet"
            value={newEvent.pet}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Açıklama</label>
          <input
            type="text"
            name="desc"
            value={newEvent.desc}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Başlangıç Zamanı</label>
          <input
            type="datetime-local"
            name="start"
            value={newEvent.start}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Bitiş Zamanı</label>
          <input
            type="datetime-local"
            name="end"
            value={newEvent.end}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="flex justify-end">
          <button onClick={handleAddEvent} className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 mr-2">
            Randevu Ekle
          </button>
          <button onClick={closeModal} className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700">
            İptal
          </button>
        </div>
      </Modal>

      {/* Mevcut randevuyu düzenleme modalı */}
      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        style={customStyles}
        contentLabel="Randevuyu Düzenle"
      >
        <h2 className="text-xl font-bold mb-4">Randevuyu Düzenle</h2>

        <div className="mb-4">
          <label className="block mb-2">Sahip</label>
          <input
            type="text"
            name="owner"
            value={newEvent.owner}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Evcil Hayvan</label>
          <input
            type="text"
            name="pet"
            value={newEvent.pet}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Açıklama</label>
          <input
            type="text"
            name="desc"
            value={newEvent.desc}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Başlangıç Zamanı</label>
          <input
            type="datetime-local"
            name="start"
            value={newEvent.start}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Bitiş Zamanı</label>
          <input
            type="datetime-local"
            name="end"
            value={newEvent.end}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>

        <div className="flex justify-end">
          <button onClick={handleEditEvent} className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 mr-2">
            Değişiklikleri Kaydet
          </button>
          <button onClick={handleDeleteEvent} className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 mr-2">
            Sil
          </button>
          <button onClick={closeEditModal} className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700">
            İptal
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default CalendarScreen;
