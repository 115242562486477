import React from "react";

function IstekTomografi({ currentRequest, setCurrentRequest, handleAddToCart }) {
  const tomografiTests = ["Cranium", "Abdomen", "Thorax", "Ekstremite", "Vertebra"];

  const handleTestClick = (test) => {
    setCurrentRequest({ ...currentRequest, category: "Tomografi", subcategory: test });
  };

  const handleAdd = () => {
    if (currentRequest.subcategory) {
      handleAddToCart({
        category: currentRequest.category,
        subcategory: currentRequest.subcategory,
        description: currentRequest.description || "-",
      });
      setCurrentRequest({ category: "Tomografi", subcategory: "", description: "" });
    } else {
      alert("Lütfen bir alt kategori seçin ve açıklama girin.");
    }
  };

  return (
    <div>
      <h3 className="text-md font-semibold mb-2">Tomografi Testleri</h3>
      <div className="grid grid-cols-3 gap-4 mb-6">
        {tomografiTests.map((test, idx) => (
          <button
            key={idx}
            className={`p-4 border rounded-lg shadow cursor-pointer hover:bg-blue-100 ${
              currentRequest.subcategory === test ? "bg-blue-100" : ""
            }`}
            onClick={() => handleTestClick(test)}
          >
            {test}
          </button>
        ))}
      </div>
      {currentRequest.subcategory && (
        <div>
          <h3 className="text-md font-semibold mb-2">Yorum Giriniz</h3>
          <textarea
            value={currentRequest.description || ""}
            onChange={(e) =>
              setCurrentRequest({ ...currentRequest, description: e.target.value })
            }
            placeholder="Yorum giriniz..."
            className="p-2 border rounded w-full mb-4"
          ></textarea>
          <button
            onClick={handleAdd}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Sepete Ekle
          </button>
        </div>
      )}
    </div>
  );
}

export default IstekTomografi;
