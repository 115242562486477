import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../../Navbar";
import Select from "react-select";


const categories = [
  "all",
  "lab",
  "istemler",
  "görüşmeler",
  "operasyon",
  "ilaç",
];
const statuses = ["To Do", "In Progress", "Done"];
const assignees = [
  "all",
  "Clinic Worker",
  "Assistant",
  "Intern",
  "Doctor",
  "Nurse",
];

function KanbanBoard({ showNavbar = true, petId = null  }) {
  const [tasks, setTasks] = useState({ todo: [], inProgress: [], done: [] });
  const [filter, setFilter] = useState("all");
  const [assigneeFilter, setAssigneeFilter] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTask, setSelectedTask] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [draggedTask, setDraggedTask] = useState(null);
  const [pets, setPets] = useState([]);

  const [newTask, setNewTask] = useState({
    title: "",
    description: "",
    assignedTo: "",
    createdBy: "",
    assignedBy: "",
    category: "lab",
    status: "To Do",
    todoPet: "", 
  });
  
  const [taskWasDragged, setTaskWasDragged] = useState(false);
  const [showHistory, setShowHistory] = useState(false); // History toggle state
  const [historyTasks, setHistoryTasks] = useState([]); // History tasks state

  const fetchTasks = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const endpoint = petId
        ? `https://api.dev1.fugesoft.com/api/tasks/petid/${petId}`
        : "https://api.dev1.fugesoft.com/api/tasks";
  
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("API Response:", response.data);
  
      const fetchedTasks = { todo: [], inProgress: [], done: [] };
      const eightHoursAgo = new Date();
      eightHoursAgo.setHours(eightHoursAgo.getHours() - 8);
  
      const historyTasks = [];
      const tasks = Array.isArray(response.data)
        ? response.data
        : response.data.tasks || [];
  
      tasks.forEach((task) => {
        if (task.status === "To Do") {
          fetchedTasks.todo.push(task);
        } else if (task.status === "In Progress") {
          fetchedTasks.inProgress.push(task);
        } else if (task.status === "Done") {
          if (new Date(task.updated_at) < eightHoursAgo) {
            historyTasks.push(task);
          } else {
            fetchedTasks.done.push(task);
          }
        }
      });
  
      setTasks(fetchedTasks);
      setHistoryTasks(historyTasks);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };
  
  
  
  
  
  
  
  

  useEffect(() => {
    fetchTasks();
  }, [petId]);

  // Fetch pets for dropdown
  const fetchPets = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get("https://api.dev1.fugesoft.com/api/pets", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const petOptions = response.data.map((pet) => ({
        value: pet._id,
        label: `${pet.name} - ${pet.owner_name}`,
      }));
      setPets(petOptions);
  
      if (petId) {
        const selectedPet = petOptions.find((pet) => pet.value === petId);
        if (selectedPet) {
          setNewTask((prev) => ({
            ...prev,
            petId: selectedPet.value,
            todoPet: selectedPet.label,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching pets:", error);
    }
  };
  
  
  

  useEffect(() => {
    fetchTasks();
    fetchPets();
  }, [petId]);


  const handleCreateTask = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.post(
        "https://api.dev1.fugesoft.com/api/tasks",
        newTask,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTasks((prevTasks) => ({
        ...prevTasks,
        todo: [...prevTasks.todo, response.data],
      }));
      setShowCreateModal(false);
      setNewTask({
        title: "",
        description: "",
        assignedTo: "",
        createdBy: "",
        assignedBy: "",
        category: "lab",
        status: "To Do",
        petId: "",
        todoPet: "",
      });
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };
  

  const handlePetSelection = (selectedOption) => {
    setNewTask((prev) => ({
      ...prev,
      petId: selectedOption.value,
      todoPet: selectedOption.label,
    }));
  };
  

  const handleStatusChange = async (task, newStatus) => {
    try {
      const token = localStorage.getItem("authToken");
      await axios.put(
        `https://api.dev1.fugesoft.com/api/tasks/${task._id}`,
        { ...task, status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const updatedTasks = { ...tasks };
      const currentCategory =
        task.status === "To Do"
          ? "todo"
          : task.status === "In Progress"
          ? "inProgress"
          : "done";
      const targetCategory =
        newStatus === "To Do"
          ? "todo"
          : newStatus === "In Progress"
          ? "inProgress"
          : "done";
  
      updatedTasks[currentCategory] = updatedTasks[currentCategory].filter(
        (t) => t._id !== task._id
      );
      updatedTasks[targetCategory] = [
        ...updatedTasks[targetCategory],
        { ...task, status: newStatus },
      ];
  
      setTasks(updatedTasks);
      setSelectedTask(null);
    } catch (error) {
      console.error("Error updating task status:", error);
    }
  };
  

  const handleDragStart = (task, category) => {
    setDraggedTask({ ...task, currentCategory: category });
    setTaskWasDragged(true); // Set to true when dragging starts
  };

  const handleDrop = (category) => {
    if (draggedTask) {
      const newStatus =
        category === "todo"
          ? "To Do"
          : category === "inProgress"
          ? "In Progress"
          : "Done";
      handleStatusChange(draggedTask, newStatus);
      setDraggedTask(null);

      // Delay resetting taskWasDragged to ensure no click event immediately triggers
      setTimeout(() => {
        setTaskWasDragged(false);
      }, 200); // Increased delay to prevent immediate click opening the task
    }
  };

  const handleTaskClick = (task, category) => {
    if (!taskWasDragged) {
      setSelectedTask({ ...task, currentCategory: category });
    }
  };

  const handleDeleteTask = async () => {
    if (selectedTask) {
      try {
        await axios.delete(
          `https://api.dev1.fugesoft.com/api/tasks/${selectedTask._id}`
        );
        const updatedTasks = { ...tasks };
        updatedTasks[selectedTask.currentCategory] = updatedTasks[
          selectedTask.currentCategory
        ].filter((t) => t._id !== selectedTask._id);
        setTasks(updatedTasks);
        setSelectedTask(null);
      } catch (error) {
        console.error("Error deleting task:", error);
      }
    }
  };

  const filteredTasks = (tasks, category) => {
    return tasks
      .filter((task) => filter === "all" || task.category === filter)
      .filter(
        (task) => assigneeFilter === "all" || task.assignedTo === assigneeFilter
      )
      .filter((task) =>
        task.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
  };

  const renderTasks = (tasks, category, label) => (
    <div
      onDragOver={(e) => e.preventDefault()}
      onDrop={() => handleDrop(category)}
      className="bg-white shadow-lg rounded-lg p-4 mb-4 space-y-4 relative"
    >
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold text-gray-800">{label}</h3>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setShowCreateModal(true);
          }}
          className="text-xl font-bold text-blue-500"
        >
          +
        </button>
      </div>
      {filteredTasks(tasks[category], category).map((task) => (
        <div
          key={task._id}
          draggable
          onDragStart={() => handleDragStart(task, category)}
          className={`p-4 bg-gray-100 rounded-lg shadow cursor-pointer relative flex flex-col border-l-4 
            ${
              task.category === "lab"
                ? "border-red-500"
                : task.category === "istemler"
                ? "border-blue-500"
                : task.category === "görüşmeler"
                ? "border-green-500"
                : task.category === "ilaç"
                ? "border-pink-500"
                : "border-purple-500"
            }`}
        >
          {/* Title as a clickable and hoverable span */}
          <span
            onClick={(e) => {
              e.stopPropagation(); // Prevent drag from triggering
              handleTaskClick(task, category); // Open task details
            }}
            className="font-bold text-gray-800 hover:underline cursor-pointer"
          >
            {task.title}
          </span>
  
          <p className="text-sm text-gray-500">Created by: {task.createdBy}</p>
          <p className="text-sm text-gray-500">Assigned by: {task.assignedBy}</p>
  
          {/* Pet Name at the bottom left, only if it exists */}
          {task.todoPet && (
            <div className="mt-auto text-left">
              <p className="text-xs text-gray-500 font-semibold">
                Pet Name: {task.todoPet}
              </p>
            </div>
          )}
  
          <div className="mt-auto text-right">
            <p className="text-xs text-gray-500 font-semibold">
              Assigned to: {task.assignedTo}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
  

  const handleToggleHistory = () => {
    setShowHistory(!showHistory);
    if (showHistory) {
      // Refetch tasks to reflect updates in the Kanban board
      fetchTasks();
    }
  };

  const renderHistoryTable = () => (
    <div className="p-4 bg-white rounded shadow-lg max-w-4xl mx-auto">
      <h2 className="text-lg font-bold mb-4">Task History</h2>
      <table className="table-auto w-full text-left">
        <thead>
          <tr className="bg-gray-200">
            <th className="px-4 py-2">Title</th>
            <th className="px-4 py-2">Description</th>
            <th className="px-4 py-2">Created At</th>
            <th className="px-4 py-2">Updated At</th>
            <th className="px-4 py-2">Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredHistoryTasks().map((task) => (
            <tr key={task._id}>
              <td className="border px-4 py-2">{task.title}</td>
              <td className="border px-4 py-2">{task.description}</td>
              <td className="border px-4 py-2">
                {new Date(task.created_at).toLocaleString()}
              </td>
              <td className="border px-4 py-2">
                {new Date(task.updated_at).toLocaleString()}
              </td>
              <td className="border px-4 py-2">
                <select
                  value={task.status}
                  onChange={async (e) => {
                    const newStatus = e.target.value;
                    try {
                      await axios.put(
                        `https://api.dev1.fugesoft.com/api/tasks/${task._id}`,
                        { ...task, status: newStatus }
                      );
                      fetchTasks();
                    } catch (error) {
                      console.error("Error updating task status:", error);
                    }
                  }}
                  className="p-1 border rounded bg-gray-50"
                >
                  <option value="To Do">To Do</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Done">Done</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        onClick={() => setShowHistory(false)}
        className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
      >
        Close History
      </button>
    </div>
  );
  

  const filteredHistoryTasks = () => {
    return historyTasks.filter((task) =>
      task.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };
  


  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {showNavbar && <Navbar />}
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search tasks..."
          className="p-2 border rounded w-1/4"
        />
        <div className="flex space-x-2">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setFilter(category)}
              className={`px-3 py-1 rounded border-l-4 ${
                filter === category
                ? category === "lab"
                ? "bg-red-500 text-white border-red-500"
                : category === "istemler"
                ? "bg-blue-500 text-white border-blue-500"
                : category === "görüşmeler"
                ? "bg-green-500 text-white border-green-500"
                : category === "ilaç"
                ? "bg-pink-500 text-white border-pink-500"
                : "bg-purple-500 text-white border-purple-500"
              : category === "lab"
              ? "bg-gray-200 text-gray-800 border-red-500"
              : category === "istemler"
              ? "bg-gray-200 text-gray-800 border-blue-500"
              : category === "görüşmeler"
              ? "bg-gray-200 text-gray-800 border-green-500"
              : category === "ilaç"
              ? "bg-gray-200 text-gray-800 border-pink-500"
              : "bg-gray-200 text-gray-800 border-purple-500"

              }`}
            >
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </button>
          ))}
        </div>
        <select
          value={assigneeFilter}
          onChange={(e) => setAssigneeFilter(e.target.value)}
          className="p-2 border rounded"
        >
          {assignees.map((assignee) => (
            <option key={assignee} value={assignee}>
              {assignee}
            </option>
          ))}
        </select>
        <button
          onClick={handleToggleHistory}
          className="bg-gray-500 text-white py-2 px-4 rounded"
        >
          {showHistory ? "Hide History" : "View History"}
        </button>
      </div>

      {showHistory ? (
        renderHistoryTable()
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>{renderTasks(tasks, "todo", "To Do")}</div>
          <div>{renderTasks(tasks, "inProgress", "In Progress")}</div>
          <div>{renderTasks(tasks, "done", "Done")}</div>
        </div>
      )}
      {selectedTask && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white rounded-lg p-6 w-full max-w-2xl relative">
            <button
              className="absolute top-2 right-2 text-gray-500"
              onClick={() => setSelectedTask(null)}
            >
              ✖
            </button>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h3 className="text-xl font-semibold">{selectedTask.title}</h3>
                <textarea
                  placeholder="Add a description..."
                  className="w-full mt-2 p-2 border rounded"
                  value={selectedTask.description || ""}
                  onChange={(e) =>
                    setSelectedTask({
                      ...selectedTask,
                      description: e.target.value,
                    })
                  }
                />
                <div className="mt-4">
                  <h4 className="text-lg font-semibold">Comments</h4>
                  <textarea
                    placeholder="Add a comment..."
                    className="w-full mt-2 p-2 border rounded"
                  />
                </div>
              </div>
              <div>
                <label className="text-sm font-semibold">Status:</label>
                <select
                  className="w-full p-2 border rounded mt-1"
                  value={selectedTask.status}
                  onChange={(e) =>
                    handleStatusChange(selectedTask, e.target.value)
                  }
                >
                  {statuses.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
                <div className="mt-4 p-4 border rounded shadow">
                  <h4 className="text-lg font-semibold">Details</h4>
                  <p className="text-sm">
                    Assigned To: {selectedTask.assignedTo}
                  </p>
                  <p className="text-sm">
                    Assigned By: {selectedTask.assignedBy}
                  </p>
                  <p className="text-sm">
                    Created By: {selectedTask.createdBy}
                  </p>
                  <p className="text-sm">Category: {selectedTask.category}</p>
                </div>
                <button
                  className="mt-4 w-full bg-red-500 text-white p-2 rounded"
                  onClick={handleDeleteTask}
                >
                  Delete Task
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showCreateModal && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
          onClick={() => setShowCreateModal(false)}
        >
          <div
            className="bg-white p-6 rounded shadow-lg w-full max-w-md"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-xl font-bold mb-4">Create Task</h2>
            <button
              className="absolute top-2 right-2 text-gray-500"
              onClick={() => setShowCreateModal(false)}
            >
              ✖
            </button>

            {/* Title */}
            <label className="block text-gray-700 font-semibold">Title</label>
            <input
              type="text"
              placeholder="Enter task title"
              value={newTask.title}
              onChange={(e) =>
                setNewTask({ ...newTask, title: e.target.value })
              }
              className="w-full mb-2 p-2 border rounded"
            />

            {/* Description */}
            <label className="block text-gray-700 font-semibold">
              Description
            </label>
            <textarea
              placeholder="Enter task description"
              value={newTask.description}
              onChange={(e) =>
                setNewTask({ ...newTask, description: e.target.value })
              }
              className="w-full mb-2 p-2 border rounded"
            />
{/* Pet Selection */}
<label className="block mb-2">Pet Name</label>
<Select
  options={pets}
  onChange={handlePetSelection}
  placeholder="Search by pet or owner name..."
  value={pets.find((pet) => pet.label === newTask.todoPet) || null}
  className="mb-4"
/>


            {/* Assigned To */}
            <label className="block text-gray-700 font-semibold">
              Assigned To
            </label>
            <select
              value={newTask.assignedTo}
              onChange={(e) =>
                setNewTask({ ...newTask, assignedTo: e.target.value })
              }
              className="w-full mb-2 p-2 border rounded"
            >
              <option value="" disabled>
                Select assignee
              </option>
              {assignees.map((assignee) => (
                <option key={assignee} value={assignee}>
                  {assignee}
                </option>
              ))}
            </select>

            {/* Created By */}
            <label className="block text-gray-700 font-semibold">
              Created By
            </label>
            <input
              type="text"
              placeholder="Enter creator name"
              value={newTask.createdBy}
              onChange={(e) =>
                setNewTask({ ...newTask, createdBy: e.target.value })
              }
              className="w-full mb-2 p-2 border rounded"
            />

            {/* Assigned By */}
            <label className="block text-gray-700 font-semibold">
              Assigned By
            </label>
            <input
              type="text"
              placeholder="Enter assigner's name"
              value={newTask.assignedBy}
              onChange={(e) =>
                setNewTask({ ...newTask, assignedBy: e.target.value })
              }
              className="w-full mb-2 p-2 border rounded"
            />

            {/* Category */}
            <label className="block text-gray-700 font-semibold">
              Category
            </label>
            <select
              value={newTask.category}
              onChange={(e) =>
                setNewTask({ ...newTask, category: e.target.value })
              }
              className="w-full mb-2 p-2 border rounded"
            >
              <option value="" disabled>
                Select category
              </option>
              {categories.slice(1).map((category) => (
                <option key={category} value={category}>
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </option>
              ))}
            </select>

            {/* Submit and Cancel Buttons */}
            <button
              onClick={handleCreateTask}
              className="bg-blue-500 text-white p-2 rounded mt-4 w-full"
            >
              Submit
            </button>
            <button
              onClick={() => setShowCreateModal(false)}
              className="bg-gray-500 text-white p-2 rounded mt-2 w-full"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default KanbanBoard;
