import React from 'react';

function SearchBar() {
  return (
    <div className="relative text-gray-600 w-full">
      <input
        type="search"
        name="search"
        placeholder="Search..."
        className="bg-white h-10 px-5 pr-10 rounded-full text-sm focus:outline-none w-full"
      />
      <button type="submit" className="absolute right-0 top-0 mt-3 mr-4">
        <svg
          className="h-4 w-4 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 56.966 56.966"
          style={{ enableBackground: "new 0 0 56.966 56.966" }}
          xmlSpace="preserve"
          width="512px"
          height="512px"
        >
          <path d="M55.146,51.887l-14.81-14.81c3.486-4.035,5.613-9.259,5.613-14.796C45.949,9.803,36.146,0,23.974,0   C11.803,0,2,9.803,2,21.974s9.803,21.974,21.974,21.974c5.537,0,10.762-2.127,14.796-5.613l14.81,14.81   c0.512,0.512,1.18,0.769,1.849,0.769s1.337-0.256,1.849-0.769C56.17,54.56,56.17,52.912,55.146,51.887z M23.974,39.349   c-9.571,0-17.374-7.803-17.374-17.374S14.403,4.601,23.974,4.601S41.349,12.403,41.349,21.974S33.546,39.349,23.974,39.349z" />
        </svg>
      </button>
    </div>
  );
}

export default SearchBar;
