import React, { useState } from "react";

function IstekLab({ currentRequest, setCurrentRequest, handleAddToCart }) {
  const [selectedTest, setSelectedTest] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [description, setDescription] = useState("");

  const labTests = [
    {
      name: "Biyokimya",
      options: [
        "Açlık Kan Şekeri(Glukoz)",
        "Albumin",
        "ALP (Alkalen Fosfataz)",
        "Alt (GPT)",
        "Amilaz",
        "Amonyak - NH3 (Plazma)",
        "AST (GOT)",
        "Bilirubin (Direkt)",
        "Bilirubin (indirekt)",
        "Bilirubin (total)",
        "BUN",
        "Canin CRP (C-Rekreatif Protein) - Kantitatif",
        "CK",
        "CK-MB",
        "Creatinin - Serum",
        "CRP (C-Reaktif Protein) - Kalitatif",
        "Fosfor (IP)",
        "GGT (Gamma Glutamin Transferaz)",
        "Globulin",
        "Kalsiyum (Ca)",
        "Klor (Cl)",
        "LDH (Laktat Dehidrogenaz)",
        "Potasyum (K)",
        "Total Protein - Serum",
        "Trigliserid",
      ],
    },
    {
      name: "Hemogram",
      options: [
        "Lökosit",
        "Lenfosit",
        "Monosit",
        "Nötrofil",
        "Eozinofil",
        "Eritrosit",
        "Hemoglobin",
        "Hematokrit",
        "MCV",
        "MCH",
        "MCHC",
        "Trombosit",
        "MPV",
      ],
    },
    {
      name: "Koagüli",
      options: ["APTT", "PT", "INR"],
    },
    {
      name: "Hormon",
      options: ["Immunfloresan"],
    },
    {
      name: "Mikroskop",
      options: [
        "Sitoloji",
        "Deri kazıntısı",
        "Tümör sitolojisi",
        "Kan perifer yayma",
      ],
    },
    {
      name: "Hızlı Test",
      options: [
        "CPV (Canine Pervo Virus)",
        "CCV (Canine Corona Virus)",
        "CDV (Canine Distemper Virus)",
        "Toxoplasma",
        "Erlishia",
        "Babesia",
        "Anaplasma",
        "Leishmania",
        "FPV (Feline Parvo Virus)",
        "FCOV (Feline Corona Virus)",
        "FIV (Feline Immuneficiency Virus)",
        "FELV (Feline Levkemi Virus)",
        "Giordia",
      ],
    },
    {
      name: "Dışkı",
      options: ["Flotasyon", "Sitoloji", "Natifmuayene"],
    },
    {
      name: "PCR",
      options: [
        "Anaplasmatacea",
        "Canine Adenovirus",
        "Canine Herpesvirus",
        "Canine Parainfluenzavirus",
        "Cryptococcus Gatti",
        "Feline Calicivirus",
        "Feline Corona Virus-RNA",
        "Feline Herpes Virus",
        "Feline Mycoplasma spp.",
        "FeLV antijen",
        "FeLV DNA Provirus",
        "Kedi Solunum Paneli",
        "Köpek Solunum Paneli",
        "Köpekte Kene ve Dış Vektörler ile Bulaşan ve Anemi Oluşturan Hastalıklar Paneli",
        "Leptospira spp",
        "Tick-Borne Diseases Multiplex",
      ],
    },
    {
      name: "İdrar",
      options: ["Sediment Analiz", "İdrar Biyokimya", "İdrar Sitoloji"],
    },
    {
      name: "Ekim",
      options: [],
    },
  ];

  const handleTestClick = (test) => {
    setSelectedTest(test.name);
    setSelectedOptions([]);
    setCurrentRequest({ ...currentRequest, category: test.name });
  };

  const handleOptionToggle = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((opt) => opt !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleAdd = () => {
    if (selectedOptions.length > 0 || description.trim()) {
      handleAddToCart({
        category: "Lab",
        subcategory: selectedTest, // Test kategorisi (ör. Biyokimya, PCR)
        test_name: selectedOptions.join(", "), // Seçilen testlerin listesi
        description, // Kullanıcının açıklaması
      });
      setSelectedTest("");
      setSelectedOptions([]);
      setDescription("");
    } else {
      alert("Lütfen en az bir seçenek veya açıklama giriniz.");
    }
  };

  return (
    <div>
      <h3 className="text-md font-semibold mb-4">Laboratuvar Testleri</h3>
      {/* Test Listesi */}
      <div className="grid grid-cols-5 gap-2 mb-6">
        {labTests.map((test, idx) => (
          <div
            key={idx}
            className={`p-2 border rounded-lg shadow cursor-pointer text-center text-sm hover:bg-blue-100 ${
              currentRequest.category === test.name ? "bg-blue-100" : ""
            }`}
            onClick={() => handleTestClick(test)}
          >
            <p className="font-semibold text-gray-700">{test.name}</p>
          </div>
        ))}
      </div>

      {/* Seçilen Test ve Opsiyonlar */}
      {selectedTest && (
        <div>
          <h3 className="text-md font-semibold mb-2">Seçenekler</h3>
          <div className="grid grid-cols-5 gap-2 mb-6">
            {labTests
              .find((test) => test.name === selectedTest)
              ?.options.map((option, idx) => (
                <div key={idx} className="flex items-center">
                  <input
                    type="checkbox"
                    id={`option-${idx}`}
                    checked={selectedOptions.includes(option)}
                    onChange={() => handleOptionToggle(option)}
                    className="mr-2"
                  />
                  <label htmlFor={`option-${idx}`} className="text-sm text-gray-700">
                    {option}
                  </label>
                </div>
              ))}
          </div>
          <h3 className="text-md font-semibold mb-2">Açıklama Giriniz</h3>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Açıklama giriniz..."
            className="p-2 border rounded w-full mb-4"
          ></textarea>
          <button
            onClick={handleAdd}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Sepete Ekle
          </button>
        </div>
      )}
    </div>
  );
}

export default IstekLab;
