import React from "react";

function IstekUSG({ currentRequest, setCurrentRequest, handleAddToCart }) {
  // USG için buton isimleri
  const usgTests = ["Abdomen", "Kardiyoloji", "Thorax", "Small Part"];

  const handleTestClick = (test) => {
    // Seçilen testi currentRequest'e ekle
    setCurrentRequest({ ...currentRequest, category: test });
  };

  const handleAdd = () => {
    if (currentRequest.category) {
      handleAddToCart({
        category: "USG",
        subcategory: "", // USG has no subcategory
        test_name: currentRequest.category, // Abdomen, Kardiyoloji, etc.
        description: currentRequest.description || "-", // User-entered description
      });
      setCurrentRequest({ category: "", description: "" });
    } else {
      alert("Lütfen bir kategori seçin ve açıklama girin.");
    }
  };
  

  return (
    <div>
      <h3 className="text-md font-semibold mb-2">USG Testleri</h3>
      <div className="grid grid-cols-4 gap-4 mb-6">
        {usgTests.map((test, idx) => (
          <button
            key={idx}
            className={`p-4 border rounded-lg shadow cursor-pointer hover:bg-blue-100 ${
              currentRequest.category === test ? "bg-blue-100" : ""
            }`}
            onClick={() => handleTestClick(test)}
          >
            {test}
          </button>
        ))}
      </div>
      {currentRequest.category && (
        <div>
          <h3 className="text-md font-semibold mb-2">Açıklama Giriniz</h3>
          <textarea
            value={currentRequest.description || ""}
            onChange={(e) =>
              setCurrentRequest({ ...currentRequest, description: e.target.value })
            }
            placeholder="Açıklama giriniz..."
            className="p-2 border rounded w-full mb-4"
          ></textarea>
          <button
            onClick={handleAdd}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Sepete Ekle
          </button>
        </div>
      )}
    </div>
  );
}

export default IstekUSG;
