import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import LoginScreen from "./screens/Authentication/LoginScreen";
import ChangePasswordScreen from "./screens/Authentication/ChangePasswordScreen";
import PetOwnerScreen from "./screens/Dashboard/Customers/PetOwnerScreen";
import PetOwnerDetailsScreen from "./screens/Dashboard/Customers/PetOwnerDetailsScreen";
import CalendarScreen from "./screens/Calender/CalenderScreen";
import OverviewKanban from "./screens/Dashboard/OverviewKanban";
import PetList from "./screens/Dashboard/HayvanListesi/PetList";
import KanbanBoard from "./screens/Dashboard/KanbanBoard/KanbanBoard";
import PetListScreen from "./screens/Dashboard/YatanHastaListesi/PetListScreen";
import PetDetails from "./screens/Dashboard/YatanHastaListesi/PetDetails";
import IstekPet from "./screens/Dashboard/YatanHastaListesi/IstekPet/IstekPet";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("authToken")
  );

  const handleTokenChange = () => {
    const token = localStorage.getItem("authToken");
    setIsAuthenticated(!!token);
    console.log("isAuthenticated durumu değişti:", !!token);
  };

  useEffect(() => {
    window.addEventListener("storage", handleTokenChange);
    return () => {
      window.removeEventListener("storage", handleTokenChange);
    };
  }, []);

  return (
    <Router>
      <div className="h-screen flex flex-col">
        {/* Header, login olmuşsa gösterilir */}
        {isAuthenticated && <Header />}

        <div className="flex flex-1">
          <div className="flex-1">
            <Routes>
              {!isAuthenticated ? (
                <>
                  <Route path="/login" element={<LoginScreen />} />
                  <Route path="*" element={<Navigate to="/login" />} />
                </>
              ) : (
                <>
                  <Route path="/" element={<OverviewKanban />} />
                  <Route path="/change-password" element={<ChangePasswordScreen />} />
                  <Route path="/patients" element={<PetOwnerScreen />} />
                  <Route
                    path="/pet-owner/:owner_id"
                    element={<PetOwnerDetailsScreen />}
                  />
                  <Route path="/calendar" element={<CalendarScreen />} />
                  <Route path="/petlist" element={<PetList />} />
                  <Route path="/kanban-board" element={<KanbanBoard />} />
                  <Route path="/pet-list" element={<PetListScreen />} />
                  <Route path="/pet-details/:petId" element={<PetDetails />} />
                  <Route path="/istek-pet/:petId" element={<IstekPet />} />
                  <Route path="/login" element={<Navigate to="/" />} />
                </>
              )}
            </Routes>
          </div>
        </div>

        {/* Footer, login olmuşsa gösterilir */}
        {isAuthenticated && <Footer />}
      </div>
    </Router>
  );
}

export default App;
